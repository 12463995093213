body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow: visible;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-orange {
  --bs-bg-opacity: 1;
  background-color: #ff6f0b;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #fff !important;
}

.orange {
  color: #ff6f0b !important;
}

.bs-orange .navbar-nav.a {
  color: #fff !important;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 1);
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: rgb(245, 248, 217);
}

.navbar-dark a {
  color: rgba(255, 255, 255, 1);
}

.bg-main {
  background: #e5e5e5 url("/src/img/bg1.jpg") no-repeat top center !important;
}

.mt-header {
  margin-top: 280px;
}

.hr-line {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
  border: none;
  border-top: 3px solid #ff6f0b;
  opacity: 1 !important ;
}

.first-letter:first-letter {
  font-size: 30px;
  color: #ff6f0b;
}

.box {
  margin-top: 50px;
}

.text-white,
.text-white a {
  color: #fff !important;
}

@media screen and (min-width: 650px) {
  .formMob {
    width: 650px;
  }
}

@media (max-width: 649px) {
  .formMob {
    width: 100%;
    overflow: hidden;
  }
}

@media screen and (min-width: 978px) {
  .send button {
    min-width: 300px;
    margin-top: 300px;
    margin-right: 10%;
  }
}

@media (max-width: 977px) {
  .send button {
    display: block;
    width: 100%;
    margin-top: 150px;
  }
  .mt-header {
    margin-top: 350px;
  }
}
